.app {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-around;
  // padding: 30px;

  // & > * {
  //   flex-grow: 0;
  //   flex-shrink: 0;
  //   flex-basis: 25%;
  // }

  .pre {
    border-radius: 2px;
    padding: 2em;
    color: lightgreen;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 1s ease;
    font-size: 11px;
  }
}
